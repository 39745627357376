import { createSlice ,PayloadAction  } from "@reduxjs/toolkit";

type initialState = {
    unAuthorized:boolean
    nameErrorMessage:string,
    emailErrorMessage:string,
    passwordErrorMessage:string,
    mobileErrorMessage:string,
    confirmPasswordErrorMessage:string,
    lastNameErrorMessage:string,
    preferredBHKErrorMessage:string,
    //project
    projectErrorMessage:string,
    developerNameErrorMessage: string,
    developerMobileErrorMessage: string,
    totalUnitsErrorMessage: string,
    cityErrorMessage: string,
    pincodeErrorMessage: string,
    stateErrorMessage: string,
    streetNameErrorMessage: string,
    contactPersonNameErrorMessage: string,
    contactPersonMobileErrorMessage: string,
    budgetErrorMessage:string,
    //banner
    titleErrorMessage: string,
    bannersImageErrorMessage: string,
    //uploadUer
    excelFileErrorMessage:string,
}

const initialState: initialState = {
    unAuthorized: false, 
    nameErrorMessage:'',
    emailErrorMessage:'',
    passwordErrorMessage:'',
    mobileErrorMessage:'',
    confirmPasswordErrorMessage:'',
    lastNameErrorMessage:'',
    preferredBHKErrorMessage:'',
    //project
    projectErrorMessage:'',
    developerNameErrorMessage: '',
    developerMobileErrorMessage: '',
    totalUnitsErrorMessage: '',
    cityErrorMessage: '',
    pincodeErrorMessage: '',
    stateErrorMessage: '',
    streetNameErrorMessage: '',
    contactPersonNameErrorMessage: '',
    contactPersonMobileErrorMessage: '',
    budgetErrorMessage:'',

    //banner
    titleErrorMessage: '',
    bannersImageErrorMessage: '',

    excelFileErrorMessage:''

}

const ErrorSlice = createSlice({
    name:'errorMessage',
    initialState,
    reducers : {
        setUnAuthorized:(state:initialState,action:PayloadAction<boolean>) => {
            state.unAuthorized = action.payload;
        },
        setNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.nameErrorMessage = action.payload;
        },
        setEmailErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.emailErrorMessage = action.payload;
        },
        setPasswordErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.passwordErrorMessage = action.payload;
        },
        setMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.mobileErrorMessage = action.payload;
        },
        setConfirmPasswordErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.confirmPasswordErrorMessage = action.payload;
        },
        setLastNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.lastNameErrorMessage = action.payload;
        },
        setPreferredBHKErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.preferredBHKErrorMessage = action.payload;
        },
       
        setProjectErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.projectErrorMessage = action.payload;
        },
        setDeveloperNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.developerNameErrorMessage = action.payload;
        },
        setDeveloperMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.developerMobileErrorMessage = action.payload;
        },
        setTotalUnitsErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.totalUnitsErrorMessage = action.payload;
        },
        setCityErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.cityErrorMessage = action.payload;
        },
        setPincodeErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.pincodeErrorMessage = action.payload;
        },
        setStateErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.stateErrorMessage = action.payload;
        },
        setStreetNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.streetNameErrorMessage = action.payload;
        },
        setContactPersonNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.contactPersonNameErrorMessage = action.payload;
        },
        setContactPersonMobileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.contactPersonMobileErrorMessage = action.payload;
        },
        setBudgetErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.budgetErrorMessage = action.payload;
        },
        
        setTitleErrorMessageNameErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.titleErrorMessage = action.payload;
        },
        setBannersImageErrorMessageErorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.bannersImageErrorMessage = action.payload;
        },
         setExcelFileErrorMessage:(state:initialState,action:PayloadAction<string>) => {
            state.excelFileErrorMessage = action.payload;
        },
        
    }
})

export default ErrorSlice.reducer

export const {
    setUnAuthorized,
    setNameErrorMessage,
    setEmailErrorMessage,
    setPasswordErrorMessage,
    setMobileErrorMessage,
    setConfirmPasswordErrorMessage,
    setLastNameErrorMessage,
    setPreferredBHKErrorMessage,
    setProjectErrorMessage,
    //  projects
    setDeveloperNameErrorMessage,
    setDeveloperMobileErrorMessage,
    setTotalUnitsErrorMessage,
    setCityErrorMessage,
    setPincodeErrorMessage,
    setStateErrorMessage,
    setStreetNameErrorMessage,
    setBudgetErrorMessage,
    setContactPersonNameErrorMessage,
    setContactPersonMobileErrorMessage,
    setTitleErrorMessageNameErrorMessage,
    setBannersImageErrorMessageErorMessage,
    setExcelFileErrorMessage
} = ErrorSlice.actions;