import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config/index'
import { urlConstants } from '../../../constants/urlConstants'

export const ReportApi = createApi({
    reducerPath: 'ReportApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    tagTypes: ['report'],
    endpoints: (builder) => ({
        getReportList: builder.query<any, any>({
            query: (searchPrams: any) => ({
                url: urlConstants.REPORT,
                method: 'GET',
                params: {
                    fromDate: searchPrams.startDate,
                    toDate: searchPrams.endDate,
                    status: searchPrams.status,
                    project: searchPrams.project,
                    page: searchPrams.page,
                    limit: 100
                }
            }),
            providesTags: ['report'],
            keepUnusedDataFor: 0

        })
    })

})
export const { invalidateTags } = ReportApi.util;

export const invalidateReportListQuery = (dispatch:any) => {
   console.log("invalidation")
   dispatch(ReportApi.util.invalidateTags(['report']))
};


export const {
    useGetReportListQuery
} = ReportApi


