import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const leadsApi = createApi({
    reducerPath: 'leadsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['leads'],
    endpoints: (builder) => ({
        getLeads: builder.query<any, any>({
            query: ({searchString,page}) => ({
                url:urlConstants.LEAD,
                method: 'GET',
                params:{
                    searchString: searchString,
                    page:page
                }
            }),
            providesTags: ['leads']
        })
    })

})

export const { useGetLeadsQuery} = leadsApi