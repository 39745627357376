import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const cpUsersApi = createApi({
    reducerPath: 'cpUsersApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['cpUsers'],
    endpoints: (builder) => ({
        getCpUsers: builder.query<any, any>({
            query: ({searchString}) => ({
                url:urlConstants?.CP_USERS,
                method: 'GET',
                params:{
                    searchString: searchString,
                }
            }),
            providesTags: ['cpUsers']
        }),
        updateCpUsersStatus: builder.mutation<any, any>({
            query: (data) => ({
                url:urlConstants?.CP_USERS_APPROVAL,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['cpUsers']
        }),
        usersBulkImport: builder.mutation<any, any>({
            query: (fileData:any) => ({
                url:urlConstants?.CP_USERS_BULK_IMPORT,
                method: 'POST',
                body:fileData
            }),
            invalidatesTags: ['cpUsers']
        }),
    })

})

export const { useGetCpUsersQuery ,useUpdateCpUsersStatusMutation,useUsersBulkImportMutation } = cpUsersApi