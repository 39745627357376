import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type initialState = {
    showAlert: {
        showModel: boolean;
        type: string;
        message: string;
        deleteAlert: boolean;
    }
}

export const showAlertInitial={
    showModel: false,
        type: '',
        message: '',
        deleteAlert: false
}

const initialState: initialState = {
    showAlert: {
        showModel: false,
        type: '',
        message: '',
        deleteAlert: false
    }
}

const AlertMessaegSlice = createSlice({
    name: 'errorMessage',
    initialState,
    reducers: {
        setShowAlert: (state: initialState, action: PayloadAction<initialState["showAlert"]>) => {
            state.showAlert = action.payload;
        }

    }
})

export default AlertMessaegSlice.reducer

export const {
    setShowAlert,

} = AlertMessaegSlice.actions;