import { configureStore,combineReducers } from "@reduxjs/toolkit";
import errorMessageReducer from "../reducer/errorMessageReducer";
import { loginApi } from "../../services/apiService/login/login";
import { signupApi } from "../../services/apiService/signup/signup";
import { bannerApi } from "../../services/apiService/banner/banner";
import { projectApi } from "../../services/apiService/projects/project";
import { cpUsersApi } from "../../services/apiService/cpUsers/cpUsers";
import { profileApi } from "../../services/apiService/profile/profile";
import AlertMessaegReducer from "../reducer/alertReducer";
import { leadsApi } from "../../services/apiService/leads/leads";
import { ReportApi } from "../../services/apiService/report/report";

const rootReducer=combineReducers({
    ErrorMessageReducer:errorMessageReducer,
    AlertMessaegReducer:AlertMessaegReducer,
    [loginApi.reducerPath]:loginApi.reducer,
    [signupApi.reducerPath]:signupApi.reducer,
    [bannerApi.reducerPath]:bannerApi.reducer,
    [projectApi.reducerPath]:projectApi.reducer,
    [cpUsersApi.reducerPath]:cpUsersApi.reducer,
    [profileApi.reducerPath]:profileApi.reducer,
    [leadsApi.reducerPath]:leadsApi.reducer,
    [ReportApi.reducerPath]:ReportApi.reducer
})

const store=configureStore({
    reducer:rootReducer,
    middleware:(getdefaultMiddleware)=>
    getdefaultMiddleware().concat([
        loginApi.middleware,
        signupApi.middleware,
        bannerApi.middleware,
        projectApi.middleware,
        cpUsersApi.middleware,
        profileApi.middleware,
        leadsApi.middleware,
        ReportApi.middleware
    ])
})

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

