import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { config } from '../../../config'
import { urlConstants } from '../../../constants/urlConstants'

export const projectApi:any = createApi({
    reducerPath: 'projectApi',
    baseQuery: fetchBaseQuery({
        baseUrl: config().BASE_URL,
        prepareHeaders: (headers: any) => {
            headers.set("Authorization", `Token ${localStorage.getItem('cp-admin-token')}`)
            return headers
        }
    }),
    
    tagTypes: ['project'],
    endpoints: (builder) => ({
        getProjects: builder.query<any, any>({
            query: ({searchString}) => ({
                url:urlConstants?.PROJECTS,
                method: 'GET',
                params:{
                    searchString: searchString,
                }
            }),
            providesTags: ['project']
        }),
        getSpProjects: builder.query<any, void>({
            query: () => ({
                url:urlConstants?.SP_PROJECTS,
                method: 'GET',
            }),
            providesTags: ['project']
        }),

        createProject: builder.mutation<any, void>({
            query: (data) => ({
                url: urlConstants?.PROJECTS,
                method: 'POST',
                body:data
            }),
            invalidatesTags: ['project']
        }),

        getSingleProject: builder.query<any, void>({
            query: (id: any) => ({
                url: `${urlConstants?.PROJECTS}/${id}`,
                method: 'GET'
            }),
            providesTags: ['project']
        }),

        editProject: builder.mutation<any, any>({
            query: ({id,projectData}:any) => ({
                url: `${urlConstants?.PROJECTS}/${id}`,
                method: 'PUT',
                body:projectData
            }),
            invalidatesTags: ['project']
        }),
        deletetProject: builder.mutation<any, any>({
            query: (id:any) => ({
                url: `${urlConstants?.PROJECTS}/${id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['project']
        })
    })

})

export const { useGetProjectsQuery,useGetSpProjectsQuery, useCreateProjectMutation, useEditProjectMutation, useGetSingleProjectQuery ,useDeletetProjectMutation} = projectApi