export const urlConstants = {
    LOGIN: '/user/admin-login',
    LOGOUT: '/user/logout',
    REGISTER: '/user/register',
    BANNER: '/banners',
    LEAD: '/lead-admin',
    PROJECTS: '/projects',
    SP_PROJECTS: '/salesforce/projects',
    CP_USERS: "/user",
    CP_USERS_APPROVAL: '/user-approval',
    CP_USERS_BULK_IMPORT: '/user/bulk-registration',
    DOWNLOAD_REPORT: 'lead-report-file',
    REPORT: '/lead-report/'
}